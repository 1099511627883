import React, {useState, useEffect} from 'react';
import _ from 'lodash';
import {motion} from 'framer-motion';
import {useTranslation} from 'react-i18next';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import SlideIndicator from './SlideIndicator';
import Overlay from './Overlay';

const variants = {
  in: {
    opacity: 0.8,
  },
  out: {
    opacity: 0,
  },
};

let timer;
const HeroSlideShow = ({images}) => {
  const {t} = useTranslation();
  const [activeIdx, setactiveIdx] = useState(0);

  useEffect(() => {
    timer = setTimeout(() => {
      setactiveIdx((prevState) => (prevState + 1) % _.size(images.nodes));
    }, 6000);

    return () => {
      clearTimeout(timer);
    };
  }, [activeIdx, images.nodes]);

  const handleOnClick = () => {
    setactiveIdx((activeIdx + 1) % _.size(images.nodes));
    clearTimeout(timer);
  };

  const captions = [
    {
      title: t('hero.auto-savvy'),
      line1: t('hero.slide-1.line-1'),
      line2: t('hero.slide-1.line-2'),
    },
    {
      title: t('hero.auto-savvy'),
      line1: t('hero.slide-2.line-1'),
      line2: t('hero.slide-2.line-2'),
    },
    {
      title: t('hero.auto-savvy'),
      line1: t('hero.slide-3.line-1'),
      line2: t('hero.slide-3.line-2'),
    },
  ];

  return (
    <div className={`relative w-full h-full`}>
      {_.map(images.nodes, (i, idx) => {
        const isActive = idx === activeIdx;
        return (
          <motion.div
            key={`hero-img-${idx}`}
            className={`absolute w-full h-full top-0 left-0`}
            variants={variants}
            animate={isActive ? 'in' : 'out'}
          >
            <GatsbyImage
              image={getImage(i)}
              className={`w-full h-full`}
              onClick={handleOnClick}
              alt={'hero-image'}
            />
          </motion.div>
        );
      })}
      <Overlay caption={captions[activeIdx]} handleOnClick={handleOnClick} />
      <div
        className={
          'h-6 w-full absolute bottom-12 flex items-center justify-center'
        }
      >
        <SlideIndicator
          activeIdx={activeIdx}
          itemCount={_.size(images.nodes)}
        />
      </div>
    </div>
  );
};

export default HeroSlideShow;

HeroSlideShow.propTypes = {
  images: PropTypes.object,
};
HeroSlideShow.defaultProps = {
  images: {},
};
