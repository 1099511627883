import React from 'react';
import PropTypes from 'prop-types';
import {GatsbyImage} from 'gatsby-plugin-image';

const PageDivider = ({image1, image2, className, hasNavHeight, isHeader}) => {
  return (
    <div
      className={`w-full h-0 min-h-page-divider lg:min-h-page-divider-lg 2xl:min-h-page-divider-2xl flex bg-black ${className}`}
    >
      <GatsbyImage
        image={image1}
        alt={'image1'}
        className={image2 ? 'w-1/2' : 'w-full'}
      />
      {image2 && (
        <GatsbyImage image={image2} alt={'image2'} className={'w-1/2'} />
      )}
    </div>
  );
};

export default PageDivider;

PageDivider.propTypes = {
  image1: PropTypes.object.isRequired,
  image2: PropTypes.object,
  className: PropTypes.string,
  isHeader: PropTypes.bool,
};
PageDivider.defaultProps = {
  image2: null,
  className: '',
  isHeader: false,
};
