import React, {useState, useEffect, useMemo, useCallback} from 'react';
import _ from 'lodash';
import {Scene} from 'scrollmagic';
import {useTranslation} from 'react-i18next';
import {graphql, useStaticQuery, navigate} from 'gatsby';
import ServiceCard from '../../components/ServiceCard';
import ContentContainer from '../../components/ContentContainer';
import SectionHeader from '../../components/SectionHeader';
import ScrollMagic from '../../services/ScrollMagic';

const Service = () => {
  const {image1, image2, image3} = useStaticQuery(graphql`
    query LandingServicesQuery {
      image1: file(name: {eq: "service01"}) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, width: 768)
        }
      }
      image2: file(name: {eq: "service02"}) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, width: 768)
        }
      }
      image3: file(name: {eq: "service03"}) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, width: 768)
        }
      }
    }
  `);

  const {t} = useTranslation();
  const [showServiceCards, setshowServiceCards] = useState(false);
  const [showSectionHeader, setshowSectionHeader] = useState(false);

  const handleEnterServiceCardScenene = useCallback(() => {
    if (!showServiceCards) setshowServiceCards(true);
  }, [showServiceCards]);

  const handleEnterServiceHeaderScene = useCallback(() => {
    if (!showSectionHeader) setshowSectionHeader(true);
  }, [showSectionHeader]);

  useEffect(() => {
    const serviceCardScene = new Scene({
      triggerElement: '#serviceSection',
      triggerHook: 0.5,
    })
      .on('enter', handleEnterServiceCardScenene)
      .addTo(ScrollMagic.Controller);

    const serviceHeaderScene = new Scene({
      triggerElement: '#serviceSection',
      triggerHook: 0.5,
    })
      .on('enter', handleEnterServiceHeaderScene)
      .addTo(ScrollMagic.Controller);

    return () => {
      ScrollMagic.Controller.removeScene(serviceCardScene);
      ScrollMagic.Controller.removeScene(serviceHeaderScene);
    };
  }, [handleEnterServiceCardScenene, handleEnterServiceHeaderScene]);

  const serviceCards = useMemo(
    () => [
      {
        title: t('service-card-1.title'),
        body: t('service-card-1.body'),
        image: image1,
        btnText: t('button.learn-more'),
        onClickBtn: () => navigate('/#aboutUs'),
      },
      {
        title: t('service-card-2.title'),
        body: t('service-card-2.body'),
        image: image2,
        btnText: t('button.explore'),
        onClickBtn: () => navigate('/Market'),
      },
      {
        title: t('service-card-3.title'),
        body: t('service-card-3.body'),
        image: image3,
        btnText: t('button.view'),
        onClickBtn: () => navigate('/AutoParts'),
      },
    ],
    [t, image1, image2, image3]
  );

  const _renderServiceCard = (i, idx) => (
    <ServiceCard
      key={'serviceCard' + idx}
      title={i.title}
      body={i.body}
      image={i.image}
      btnText={i.btnText}
      onClickBtn={i.onClickBtn}
      index={idx}
      show={showServiceCards}
      transition={{
        delay: idx * 0.125,
      }}
    />
  );

  return (
    <div id={'serviceSection'} className={`bg-grey`}>
      <ContentContainer>
        <>
          <div className={`py-20`}>
            <SectionHeader
              title={t('services')}
              subTitle={t('services-sub-header')}
              showHeader={showSectionHeader}
            />
            <div
              className={`flex flex-row justify-between flex-wrap gap-x-4 gap-y-12 xl:gap-x-6`}
            >
              {_.map(serviceCards, (i, idx) => _renderServiceCard(i, idx))}
            </div>
          </div>
        </>
      </ContentContainer>
    </div>
  );
};

export default Service;
