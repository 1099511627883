import React, {useEffect, useCallback, useContext} from 'react';
import _ from 'lodash';
import {graphql, useStaticQuery} from 'gatsby';
import {LazyLoadComponent} from 'react-lazy-load-image-component';
import {GatsbyImage} from 'gatsby-plugin-image';
import Hero from './Hero';
import Service from './Sevice';
import About from './About';
import PageDivider from '../../components/PageDivider';
import OurWorks from './OurWorks';
import Contact from './Contact';
import Utils from '../../util/util';
import AppServices from '../../services/App';
import Enum from '../../util/enum.util';

const LandingPage = () => {
  const data = useStaticQuery(graphql`
    query LandingPageQuery {
      image1: file(name: {eq: "misc01"}) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
      image2: file(name: {eq: "misc02"}) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
      image3: file(name: {eq: "misc03"}) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
    }
  `);

  return (
    <div id={'homePage'} className={`w-screen`}>
      <Hero />
      <Service />
      <PageDivider
        image1={Utils.getGatsbyImageData(data, 'image1')}
        image2={Utils.getGatsbyImageData(data, 'image2')}
      />
      <About />
      <OurWorks />
      <LazyLoadComponent>
        <Contact />
        <GatsbyImage
          image={Utils.getGatsbyImageData(data, 'image3')}
          className={`w-full h-120 opacity-80`}
          alt={'Auto Savvy'}
        />
      </LazyLoadComponent>
    </div>
  );
};

export default LandingPage;
