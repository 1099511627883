import React from 'react';
// import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {navigate} from 'gatsby';
import Button from '../../components/Button';
import Header from '../../components/Typograhpy/Header';

const Contact = () => {
  const {t} = useTranslation();
  const onClickBtn = () => {
    navigate('/Contact');
  };
  return (
    <div className={'bg-grey flex flex-col items-center py-20'}>
      <Header mode={'h2'} value={t('wish-to-know-more')} className={'mb-6'} />
      <div className={'w-px h-20 bg-white'} />
      <Button
        title={t('button.contact-us')}
        className={'mt-8'}
        onClickBtn={onClickBtn}
      />
    </div>
  );
};

export default Contact;

Contact.propTypes = {};
Contact.defaultProps = {};
