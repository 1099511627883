import React, {useMemo} from 'react';
import {motion, AnimatePresence} from 'framer-motion';
import PropTypes from 'prop-types';
import Header from '../Typograhpy/Header';
import Text from '../Typograhpy/Text';
import * as Styles from './hero.module.scss';

const varients = {
  enter: {
    y: 0,
    opacity: 1,
  },
  exit: {
    y: -20,
    opacity: 0,
  },
};

const initial = {
  y: 20,
  opacity: 0,
};

const delayInterval = 0.075;

const Overlay = ({caption, handleOnClick}) => {
  return useMemo(
    () => (
      <div
        className={
          'absolute top-0 left-0 w-full h-full flex justify-center items-center'
        }
        onClick={handleOnClick}
        onKeyPress={handleOnClick}
        role="button"
        tabIndex="0"
      >
        <div
          className={
            'absolute bg-black top-0 left-0 right-0 bottom-0 opacity-40 z-10'
          }
        />
        <motion.div className={'flex flex-col items-center z-20'}>
          <AnimatePresence exitBeforeEnter>
            <motion.div
              key={'auto-savvy'}
              className={Styles.autoSavvyLogo + ' text-white'}
              variants={varients}
              animate={'enter'}
              exit={'exit'}
              initial={initial}
              transition={{
                delay: 0,
              }}
            >
              {caption.title}
            </motion.div>
          </AnimatePresence>
          <AnimatePresence exitBeforeEnter>
            <motion.div
              key={caption.line1}
              variants={varients}
              animate={'enter'}
              exit={'exit'}
              initial={initial}
              transition={{
                delay: delayInterval,
              }}
            >
              <Header value={caption.line1} white />
            </motion.div>
          </AnimatePresence>
          <AnimatePresence exitBeforeEnter>
            <motion.div
              key={caption.line2}
              variants={varients}
              animate={'enter'}
              exit={'exit'}
              initial={initial}
              transition={{
                delay: 2 * delayInterval,
              }}
            >
              <Text value={caption.line2} />
            </motion.div>
          </AnimatePresence>
        </motion.div>
      </div>
    ),
    [caption, handleOnClick]
  );
};

export default Overlay;

Overlay.propTypes = {
  caption: PropTypes.object,
  handleOnClick: PropTypes.func,
};
Overlay.defaultProps = {
  captionn: {},
  handleOnClick: () => {},
};
