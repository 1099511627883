import React, {useState, useEffect} from 'react';
// import PropTypes from 'prop-types';
import {graphql, useStaticQuery} from 'gatsby';
import {useTranslation} from 'react-i18next';
import {motion} from 'framer-motion';
import {GatsbyImage} from 'gatsby-plugin-image';
import {Scene} from 'scrollmagic';
import ContentContainer from '../../components/ContentContainer';
import SectionHeader from '../../components/SectionHeader';
import Text from '../../components/Typograhpy/Text';
import Button from '../../components/Button';
import Utils from '../../util/util';
import ScrollMagic from '../../services/ScrollMagic';

const initDelay = 0.5;
const childDelay = 0.125;

const variants = {
  show: {
    opacity: 1,
    transition: {
      delay: initDelay,
    },
  },
  hidden: {
    opacity: 0,
  },
};

const textVariants = {
  show: {
    y: 0,
    transition: {
      delay: initDelay + childDelay,
    },
  },
  hidden: {
    y: 20,
  },
};

const imgVariants = {
  show: {
    y: 0,
    transition: {
      delay: initDelay + childDelay * 2,
    },
  },
  hidden: {
    y: 20,
  },
};

const About = () => {
  const data = useStaticQuery(graphql`
    query LandingAboutQuery {
      file(name: {eq: "about01"}) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
    }
  `);

  const {t} = useTranslation();

  const [showContent, setshowContent] = useState(false);

  const handleEnterAboutUsScene = () => {
    if (!showContent) setshowContent(true);
  };

  useEffect(() => {
    const aboutUsScene = new Scene({
      triggerElement: '#aboutUs',
      triggerHook: 0.5,
    })
      .on('enter', handleEnterAboutUsScene)
      .addTo(ScrollMagic.Controller);

    return () => {
      ScrollMagic.Controller.removeScene(aboutUsScene);
    };
  }, []);

  return (
    <div id={'aboutUs'} className={`bg-grey py-20`}>
      <ContentContainer>
        <>
          <SectionHeader
            title={t('about-us')}
            subTitle={t('about-sub-header')}
            showHeader={showContent}
          />
          <motion.div
            className={`flex flex-col lg:flex-row gap-12`}
            variants={variants}
            animate={showContent ? 'show' : 'hidden'}
          >
            <div className={`w-full lg:w-7/12`}>
              <Text value={t('about-para-1')} />
              <br />
              <Text value={t('about-para-2')} />
              {/* <Button title={t('button.learn-more')} className={`mt-12`} /> */}
            </div>
            <motion.div className={`w-full  lg:w-5/12`} variants={imgVariants}>
              <GatsbyImage
                image={Utils.getGatsbyImageData(data)}
                alt={'about-image'}
              />
            </motion.div>
          </motion.div>
        </>
      </ContentContainer>
    </div>
  );
};

export default About;

About.propTypes = {};
About.defaultProps = {};
