import React from 'react';
import {motion} from 'framer-motion';
import PropTypes from 'prop-types';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';
import Text from './Typograhpy/Text';
import Header from './Typograhpy/Header';
import Button from './Button';

const variants = {
  show: {
    opacity: 1,
    y: 0,
  },
  hidden: {
    opacity: 0,
    y: 30,
  },
};

const ServiceCard = ({
  image,
  title,
  body,
  btnText,
  onClickBtn,
  className,
  transition,
  show,
}) => {
  return (
    <motion.div
      className={`bg-black flex flex-col overflow-hidden ${className}
      h-service-card w-full lg:w-1/3
      md:h-service-card-lg lg:max-w-service-card
      `}
      variants={variants}
      animate={show ? 'show' : 'hidden'}
      transition={transition}
    >
      <GatsbyImage
        image={getImage(image)}
        className={``}
        alt={`image-${title}`}
      />
      <div className={`p-6 xl:p-8 h-3/5 flex flex-col justify-start`}>
        <Header mode={'h2'} value={title} className={`mb-3`} />
        <Text value={body} mode={'sm'} className={`mb-auto`} />
        <Button title={btnText} className={``} onClickBtn={onClickBtn} />
      </div>
    </motion.div>
  );
};

export default ServiceCard;

ServiceCard.propTypes = {
  image: PropTypes.object.isRequired,
  title: PropTypes.string,
  body: PropTypes.string,
  btnText: PropTypes.string,
  onClickBtn: PropTypes.func,
  className: PropTypes.string,
  transition: PropTypes.object,
  show: PropTypes.bool,
};

ServiceCard.defaultProps = {
  title: '',
  body: '',
  btnText: 'Button',
  onClickBtn: () => {},
  className: '',
  transition: {},
  show: true,
};
