import React, {useState, useEffect} from 'react';
// import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {motion} from 'framer-motion';
import {Scene} from 'scrollmagic';
import {graphql, useStaticQuery, navigate} from 'gatsby';
import {GatsbyImage} from 'gatsby-plugin-image';
import ContentContainer from '../../components/ContentContainer';
import SectionHeader from '../../components/SectionHeader';
import Text from '../../components/Typograhpy/Text';
import Button from '../../components/Button';
import Utils from '../../util/util';
import ScrollMagic from '../../services/ScrollMagic';
import constants from '../../constants';

const initDelay = 0.5;
const childDelay = 0.125;

const variants = {
  show: {
    opacity: 1,
    transition: {
      delay: initDelay,
    },
  },
  hidden: {
    opacity: 0,
  },
};

const textVariants = {
  show: {
    y: 0,
    opacity: 1,
    transition: {
      delay: initDelay + childDelay * 2,
    },
  },
  hidden: {
    y: 20,
    opacity: 0,
  },
};

const imgVariants = {
  show: {
    y: 0,
    transition: {
      delay: initDelay + childDelay,
    },
  },
  hidden: {
    y: 20,
  },
};

const OurWorks = () => {
  const data = useStaticQuery(graphql`
    query LandingOurWorksQuery {
      file(name: {eq: "repair01"}) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
    }
  `);

  const {t} = useTranslation();

  const handleEnterOurWorksScene = () => {
    if (!showContent) setshowContent(true);
  };

  const [showContent, setshowContent] = useState(false);

  useEffect(() => {
    const ourWorksScene = new Scene({
      triggerElement: '#ourWorks',
      triggerHook: 0.5,
    })
      .on('enter', handleEnterOurWorksScene)
      .addTo(ScrollMagic.Controller);

    return () => {
      ScrollMagic.Controller.removeScene(ourWorksScene);
    };
  }, []);

  const handleClickOurWork = () => {
    navigate('/Contact', {
      state: {
        queryType: constants.QUERY_TYPE.GENERAL,
      },
    });
  };

  return (
    <div id={'ourWorks'} className={`bg-black py-20`}>
      <ContentContainer>
        <>
          <SectionHeader title={t('our-works')} showHeader={showContent} />
          <motion.div
            className={`flex flex-col lg:flex-row gap-12`}
            variants={variants}
            animate={showContent ? 'show' : 'hidden'}
            initial={'hidden'}
          >
            <motion.div
              className={`w-full lg:w-5/12 h-96 `}
              variants={imgVariants}
            >
              <GatsbyImage
                image={Utils.getGatsbyImageData(data)}
                alt={'works-image'}
                className={'h-full'}
              />
            </motion.div>
            <motion.div className={`w-full lg:w-7/12`} variants={textVariants}>
              <Text value={t('our-works-para-1')} />
              <br />
              <Text value={t('our-works-para-2')} />
              <Button
                title={t('button.arrange-booking')}
                className={`mt-12`}
                onClickBtn={handleClickOurWork}
              />
            </motion.div>
          </motion.div>
        </>
      </ContentContainer>
    </div>
  );
};

export default OurWorks;

OurWorks.propTypes = {};
OurWorks.defaultProps = {};
