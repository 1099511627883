import React from 'react';
// import _ from 'lodash';
// import {motion} from 'framer-motion';
import PropTypes from 'prop-types';

const SlideIndicator = ({activeIdx, itemCount}) => {
  const _renderIndicator = () => {
    const indicators = [];
    for (let i = 0; i < itemCount; i++) {
      indicators.push(
        <div
          key={`indicator-${i}`}
          className={`transition-all h-px w-9 z-10 ${
            activeIdx === i ? 'bg-primary' : 'bg-gray-700'
          }`}
        />
      );
    }
    return indicators;
  };
  return (
    <div className={`relative h-auto w-auto`}>
      <div className={`absolute h-full w-full bg-black opacity-60`} />
      <div
        className={`h-full w-full flex flex-row justify-cneter items-center gap-3 px-3 py-2`}
      >
        {_renderIndicator()}
      </div>
    </div>
  );
};

export default SlideIndicator;

SlideIndicator.propTypes = {
  activeIdx: PropTypes.number.isRequired,
  itemCount: PropTypes.number.isRequired,
};
SlideIndicator.defaultProps = {};
