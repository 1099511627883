import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import HeroSlideShow from '../../components/HeroSlideShow';

const Hero = () => {
  const {images} = useStaticQuery(graphql`
    query HeroImageQuery {
      images: allFile(
        filter: {relativePath: {regex: "/hero/"}}
        sort: {fields: name, order: ASC}
      ) {
        nodes {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
          }
        }
      }
    }
  `);

  return (
    <div className={`bg-black h-screen w-screen`}>
      <HeroSlideShow images={images} />
    </div>
  );
};

export default Hero;

Hero.propTypes = {};
Hero.defaultProps = {};
